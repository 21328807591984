// datepicker JS
$('.date_calender').datepicker({
  format: "dd - mm - yyyy"
});

$('.monthYear_calender').datepicker({
  format: "MM - yyyy"
});

$('.year_calender').datepicker({
  format: "yyyy"
});

/** SIDEBAR JS **/
function openNav() {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("main").style.marginLeft = "230px";
  document.getElementById("openbtn").style.display = "none";

}

function closeNav() {
  document.getElementById("mySidebar").style.left = "-240px";
  document.getElementById("main").style.marginLeft = "0";
  document.getElementById("openbtn").style.display = "block";
}

function openCloseUserMenu() {
  if (document.getElementById("openUserMenu").classList.contains("hide")) {
    document.getElementById("openUserMenu").classList.remove("hide");
    document.getElementById("openUserMenu").classList.add("show");
    document.getElementById("openUserMenu").style.display = "block";
  } else {
    document.getElementById("openUserMenu").classList.remove("show");
    document.getElementById("openUserMenu").classList.add("hide");
    document.getElementById("openUserMenu").style.display = "none";
  }
}

// Data table shorting JS
$(function () {
  $("#alerts_datatable").DataTable({
    // "scrollY":'50vh',
    // "scrollX": true,
    // "scrollCollapse": true,
    "paging": true,
    "lengthChange": false,
    "searching": false,
    "ordering": false,
    "info": false,
    "autoWidth": false
  });
});

// FOR overFlowScrollList Scroll JS
$(document).ready(function () {
  //$('.mediaFeedsScroll').slimScroll({
  //    height: '365px'
  //});
  if (document.getElementById("openUserMenu")) {
    document.getElementById("openUserMenu").style.display = "none";
  }
});

$(".moreContent_Sec").hide();
$(document).ready(function () {
  $(".moreCommonNamesBtn").click(function () {
    $(".moreContent_Sec").slideToggle();
  });

  $(".close_moreContent").click(function () {
    $(".moreContent_Sec").slideToggle();
  });
});

$(".viewHistoricalData_sec").hide();
$(document).ready(function () {
  $(".viewHistoricalData").click(function () {
    $(".viewHistoricalData_sec").toggle();
  });
});

// FOR INFO - TOOLITIP popover
$("[data-toggle=popover]").popover({
  html: true,
  content: function () {
    return $('#popover-content').html();
  }
})
